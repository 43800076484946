<template>
    <div>

        <div class="addComment pr-1">
            <b-avatar size="35" class="avatar" :src="avatar" />
            <div class="textarea-with-attachment">
                <template v-if="showCommentForm">
                    <div class="form-box">
                        <div>
                            <b-form-textarea ref="commentTextarea" class="mb-1" v-model="record.comment" placeholder="Add a comment…" rows="3" />
                        </div>

                        <b-button @click="saveBtn" size="sm" :disabled="loading" variant="primary" class="mr-1">
                            <b-spinner v-if="loading" small />
                            Save
                        </b-button>
                        <b-button @click="cancelBtn" size="sm" :disabled="loading" variant="light">
                            Cancel
                        </b-button>
                    </div>
                </template>
                <template v-else>
                    <div class="box" @click="showCommentFormBtn()">
                        Add a comment…
                    </div>
                </template>
                <div class="attachment-upload-btn over-right">
                    <feather-icon icon="UploadIcon" size="16" />
                    <upload-image class="uploadBtn" @click="showCommentFormBtn()" @saveUploadImage="saveUploadImage" ref="uploadImage" />
                </div>
            </div>
        </div>
        
        <div class="topic-attachments mb-2" v-if="record.attachments && record.attachments.length > 0">
            <div v-for="(item,index) in record.attachments" :key="index" class="attachment">
                <img :src="item" alt="" />
            </div>
        </div>

    </div>
</template>

<script>
import {
    BButton,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BAlert,
    BSpinner,
    BFormCheckbox,
    BAvatar,
} from "bootstrap-vue";
import axios from "axios";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import * as Helpers from '../../../Helpers';
import { VueEditor } from "vue2-editor";
import UploadImage from "../../components/Model/UploadImage.vue";
export default {
    components: {
        BButton,
        BFormInput,
        BFormTextarea,
        BFormGroup,
        BAlert,
        BSpinner,
        BFormCheckbox,
        ToastificationContent,
        vSelect,
        VueEditor,
        BAvatar,
        UploadImage,
    },
    data() {
        return {
            baseVuePath: "@view/src/views/components/themes/",
            baseReactNativePath: "@src/components/themes/",
            vuePath: "@view/src/views/components/themes/",
            reactNativePath: "@src/components/themes/",
            errorMsg: "",
            loading: false,
            alertMessageToast: "",
            record: {
                // default_status: 0,
                attachments: []
            },
            avatar: '',
            showCommentForm: false,
        };
    },
    computed: {
        hasError() {
            return this.errorMsg != "";
        },
    },
    watch: {
    },
    mounted() {
        if (localStorage.getItem("Datauser")) {
            var user_name = JSON.parse(localStorage.getItem("Datauser"));
            if (user_name.user.avatar) {
                this.avatar = user_name.user.avatar;
            }
        }
    },
    methods: {
        attachmentBtn(){
            setTimeout(() => {
                if (this.$refs.uploadImage) {
                    this.$refs.uploadImage.click();
                }
            }, 100);
        },
        saveUploadImage(url) {
            this.showCommentFormBtn();
            console.log('url',url);
            this.record.attachments.push(url);
        },
        showCommentFormBtn(){
            this.showCommentForm = true;
            this.$nextTick(() => {
                this.$refs.commentTextarea.focus();
            });
        },
        cancelBtn(){
            this.showCommentForm = false;
            this.record = {
                attachments: [],
            };
        },
        showToast(variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Notification",
                    icon: "BellIcon",
                    text: this.alertMessageToast,
                    variant,
                },
            });
        },
        clearErrorMsg() {
            this.errorMsg = "";
        },
        resetModal() {
            this.errorMsg = "";
        },
        async saveBtn() {
            // let status = await this.checkValidation();
            // if (!status) {
            //     return;
            // }

            this.loading = true;
            try {
                const resp = await axios.post(process.env.VUE_APP_API_URL + "/topic/comment/"+this.topic_id,
                    this.record,
                    {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("_t"),
                        },
                    });
                this.alertMessageToast = resp.data.message;
                this.showToast("success");
                this.$emit('emitTopicBoardCommentAdded');
                this.loading = false;
                this.cancelBtn();
            } catch (error) {
                this.errorMsg = Helpers.catchFunction(error);
                this.loading = false;
                this.alertMessageToast = this.errorMsg;
                this.showToast("danger");
            }
        },
        checkValidation() {
            if (!this.record.comment) {
                this.errorMsg = "Comment field is required."
                return;
            }
            return "ok";
        },
    },
    props: [
        "manage",
        "topic_id",
    ],
};
</script>
